.Login {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6a0505ee;
}

.Login .welcome {
  font-size: 20px;
  color: #6a0505;
  margin-bottom: 30px;
}

.Login .container {
  background-color: white;
  padding: 0px;
  width: 50%;
  border-radius: 20px;
  position: relative;
}

.loginIconCont {
  position: absolute;
  top: -120px;
  padding: 20px;
  background-color: white;
  border-radius: 50%;
  left: calc(50% - 100px);
}

.loginIcon {
  font-size: 150px !important;
  color: #6a0505;
}

.Login .Company_Logo {
  width: 400px;
  height: 400px;
}

@media (max-width: 1124px) {
  .Login {
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;
  }

  .Login .Company_Logo {
    height: 150px;
    width: auto;
  }

  .Login .welcome {
    font-size: 20px;
    color: white;
    margin-bottom: 30px;
  }

  .Login .container {
    width: 90%;
  }

  .loginIconCont {
    position: absolute;
    top: -85px;
    padding: 20px;
    background-color: white;
    border-radius: 50%;
    left: calc(50% - 70px);
  }

  .loginIcon {
    font-size: 100px !important;
    color: #6a0505;
  }
}
