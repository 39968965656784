.MuiButton-root {
  color: var(--text-primary);
}

.MuiButton-endIcon {
  margin: 0 !important;
}

.MuiButton-label {
  justify-content: normal;
}

.center {
  justify-content: center !important;
}

.btn {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  font-size: 15px !important;
}

.small {
  font-size: 15px !important;
}

.btn:hover {
  background-color: #ac1e1c !important;
}

.deleteBtn:hover {
  background-color: #af0a17 !important;
}

@media (max-width: 600px) {
  .small {
    font-size: 12px !important;
  }
}
