.textAreaInput {
  margin: 10px 20px 0 20px;
  width: 95%;
}

.textAreaInputLarge {
  margin: 10px 0 0 0;
  width: 100%;
}

.textAreaInputFull {
  margin: 0;
  width: 100%;
  background-color: white !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 0 !important;
}

.area_text {
  /* width: 100%; */
  background-color: var(--input-Area);
  /* margin: 15px 0 60px 0 !important; */
  padding: 10px;
  border: solid 1px #b8b8b8;
  border-radius: 4px;
}

@media (max-width: 1124px) {
  .textAreaInput {
    margin: 10px 0 !important;
    width: 100%;
  }
}
