.rating {
  width: 100% !important;
  justify-content: space-between;
}

.rating input {
  display: none;
}

.rating .MuiRating-iconFilled {
  width: 100% !important;
  display: flex;
  justify-content: center;
  color: #6a0505;
}

.rating label:last-child {
  display: none;
}

.scoreSmall {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  /* width: 100%; */
  display: inline-block;
}

.greyNumberBox h3 {
  margin: 5px 0 0;
  font-weight: 500;
}

@media (max-width: 600px) {
  .scoreSmall {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}
